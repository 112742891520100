import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig={
  apiKey: "AIzaSyB1sMCYmlW0UM3mCu0qC2qiVlOR_FZnZE4",
  authDomain: "rosella-c054f.firebaseapp.com",
  projectId: "rosella-c054f",
  storageBucket: "rosella-c054f.appspot.com",
  messagingSenderId: "390006502831",
  appId: "1:390006502831:web:4b21b836dc74ff842c6b5a"
};

const app=initializeApp(firebaseConfig);
const storage=getStorage(app);
const db = getFirestore(app);

export {db,storage};
