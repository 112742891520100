import {useState,useEffect} from 'react';
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth';
import {doc,collection,getDoc,getDocs,onSnapshot} from 'firebase/firestore';
import {db} from './firebase';

const Sign=()=>{
  const auth=getAuth();
  const [email,setEmail]=useState('');
  const [password,setPassword]=useState('');
  const [visible,setVisible]=useState(false);

  useEffect(()=>{
    if(window.dev){
      sign();
    }
  },[])

  function sign(){
    window.setLoading(true);
    signInWithEmailAndPassword(auth,window.dev?'fabrika@rosellahome.com':email,window.dev?'681729':password).then((e)=>{
      getCompany(e.user.email);
    }).catch(()=>{
      window.setLoading(false);
      window.setAlert({message:'Giriş Başarısız.'})
    })
  }
  function getCompany(userEmail){
    getDoc(doc(db,'main','infos')).then((doc)=>{
      window.doc.infos=doc.data();
      window.timeDif=Date.now()-parseInt(doc._document.readTime.timestamp.seconds+'000');
      const admin=doc.data().auth?.admin?.find((i)=>(i.email==userEmail));
      const store=doc.data().auth?.store?.find((i)=>(i.email==userEmail));
      const sales=doc.data().auth?.sales?.find((i)=>(i.email==userEmail));
      window.auth=admin?'admin':store?'store':sales?'sales':null;
      window.setSigned('Signed');
      window.setLoading(false);
    }).catch((e)=>{
      window.setLoading(false);
      window.setAlert({message:'Bir şeyler ters gitti.'})
    })
  }

  return (
    <div style={{width:'100%',height:'100%'}}>
      <div style={{width:'70%',height:'70%',background:window.color.five,borderRadius:10}}>
        <label style={{paddingTop:50}}>
          <div style={{fontSize:20,letterSpacing:2,fontWeight:'bold'}}>
            ROSELLA
          </div>
          <div style={{fontSize:11,letterSpacing:2,marginTop:-5}}>
            Home Consept
          </div>
        </label>
        <div style={{flex:1,width:'100%'}}>
          <div style={{padding:3}}>
            Email
          </div>
          <input
          style={{textTransform:'lowercase',padding:10,borderRadius:10,width:'70%'}}
          type='email'
          value={email}
          onClick={()=>{window.setSigned(false)}}
          onChange={(e)=>{setEmail(e.target.value)}}/>
        </div>
        <div style={{flex:1,width:'100%'}}>
          <div style={{padding:3,flexDirection:'row'}}>
            Şifre
            <img
            onClick={()=>{setVisible(!visible)}}
            style={{width:17,height:17,position:'absolute',marginLeft:60}}
            src={require('./Assets/EyeIcon.png')}/>
          </div>
          <input
          style={{padding:10,borderRadius:10,width:'70%'}}
          value={password}
          type={visible?'text':'password'}
          onClick={()=>{window.setSigned(false)}}
          onChange={(e)=>{setPassword(e.target.value)}}/>

        </div>

        <div style={{flex:1,width:'100%',justifyContent:'space-evenly'}}>
          <div style={{}}>
            <button
            style={{width:100,borderRadius:10,padding:10}}
            disabled={window.loading||email==''||password==''?true:false}
            onClick={()=>{sign()}}>
              Giriş
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Sign;
