import {useState} from 'react';

const Product=({product,setImageView})=>{
  const supplier=product.company=='rosella'?false:true;
  const cost=product.price?product.price:0;
  const shipping=Math.round(cost*0.05);
  const sellingPrice=(product.price+shipping)+((product.price+shipping)*(product.benefit/100));
  const onePayment=sellingPrice+(sellingPrice*0.02);
  const threePayment=sellingPrice+(sellingPrice*0.08);
  const twentyFivePayment=sellingPrice+(sellingPrice*0.15);
  const sixPayment=sellingPrice+(sellingPrice*0.18);
  const setupPrice=Math.round(sellingPrice*0.05);
  const storePrice=(sellingPrice+setupPrice)+((sellingPrice+setupPrice)*0.45)
  const minStorePrice=storePrice-(storePrice*(0.15));
  const employee=minStorePrice-(minStorePrice*(0.10));
  const [more,setMore]=useState(false);

  return(
    <div style={{width:'100%',background:window.color.five,borderRadius:20,marginBottom:5,paddingBottom:10}}>
      <div style={{width:'95%',padding:10,justifyContent:'start',flexDirection:'row',justifyContent:'space-between'}}>
        <div style={{}}>
          <div style={{fontWeight:'600',textTransform:'Uppercase'}}>
            {product.name==''?'İsimsiz':product.name}
          </div>
          <div style={{fontSize:11,textTransform:'capitalize'}}>
            {product.company+(product.group?'/'+(product.group+(product.group2?'/':'')+product.group2):'')}
          </div>
          <div style={{fontSize:12,fontWeight:'500',display:storePrice>0?'flex':'none'}}>
            {Math.round(storePrice).toLocaleString()}₺
          </div>
        </div>
        {window.auth=='admin'?
        <div style={{flexDirection:'row'}}>
        <button
        onClick={()=>{window.setAdd({item:product,action:'edit'})}}
        style={{background:0,color:'black',fontSize:10}}>
          <img
          style={{width:20}}
          src={require('../Assets/EditIcon.png')}/>
          Düzenle
        </button>

        {window.dev?
          <button
          onClick={()=>{window.setAdd({item:product,action:'stock'})}}
          style={{background:0,color:'black',fontSize:10}}>
            <img
            style={{width:20}}
            src={require('../Assets/StockIcon.png')}/>
            Stok Ekle
          </button>
        :null}

        </div>
        :null}
      </div>
      <div style={{flexDirection:'row',width:'100%',justifyContent:'start',marginTop:10,marginBottom:10}}>
        {product.images?.map((img,index)=>{
          return(
            <img
            key={index}
            onClick={()=>{setImageView(img.adress)}}
            style={{minWidth:'100%',maxWidth:'100%',aspectRatio:16/9,objectFit:'cover',borderRadius:5}}
            src={img.adress}/>
          );
        })}
      </div>

      <label
      onClick={()=>{setMore(!more)}}
      style={{fontSize:11,padding:10,width:'20%'}}>
        <img
        style={{width:25,height:25,transform:more?'rotate(270deg)':'rotate(90deg)'}}
        src={require('../Assets/ArrowIcon.png')}/>
      </label>
      {more?
      <>
      <div style={{flexDirection:'row',width:'100%',justifyContent:'space-evenly',paddingTop:20,paddingBottom:20}}>
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:12}}>
            <div style={{fontSize:14,fontWeight:'600'}}>BOYUTLAR</div>
            <div style={{flexDirection:'row',justifyContent:'space-between'}}>
              <div style={{padding:3}}>
                Yükseklik
                <div style={{fontWeight:'700',fontSize:12}}>{product.height?product.height:0}cm</div>
              </div>
              <div style={{padding:3}}>
                Genişlik
                <div style={{fontWeight:'700',fontSize:12}}>{product.width?product.width:0}cm</div>
              </div>
              <div style={{padding:3}}>
                Derinlik
                <div style={{fontWeight:'700',fontSize:12}}>{product.depth?product.depth:0}cm</div>
              </div>
            </div>
            <div style={{flexDirection:'row',justifyContent:'space-between'}}>
              <div style={{padding:3}}>
                Yük Kapasite
                <div style={{fontWeight:'700',fontSize:12}}>{product.capacity?product.capacity:0}kg</div>
              </div>
              <div style={{padding:3}}>
                Profil Gen.
                <div style={{fontWeight:'700',fontSize:12}}>{product.profileWidth?product.profileWidth:0}cm</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:14,fontWeight:'600'}}>
            TÜR
          </div>
          <div style={{display:'block',fontSize:12,maxHeight:100,fontWeight:'bold'}}>
            <div style={{padding:3}}>
              {!product.color[0]?
                <div style={{alignSelf:'start'}}>Bulunmamakta.</div>
              :null}
              {product.color.map((i,index)=>{return(
                <div key={index} style={{textTransform:'capitalize'}}>{i}</div>
              )})}
            </div>
          </div>
        </div>
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:14,fontWeight:'600'}}>
            ALT-TÜR
          </div>
          <div style={{display:'block',fontSize:12,maxHeight:100,fontWeight:'bold'}}>
            <div style={{padding:3}}>
              {!product.color2[0]?
                <div style={{alignSelf:'start'}}>Bulunmamakta.</div>
              :null}
              {product.color2.map((i,index)=>{return(
                <div key={index} style={{textTransform:'capitalize'}}>{i}</div>
              )})}
            </div>
          </div>
        </div>
      </div>
      <div style={{flexDirection:'row',width:'100%',justifyContent:'space-evenly',paddingTop:20}}>
        {window.auth=='admin'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:12}}>
            <div style={{fontSize:14,fontWeight:'600',paddingBottom:3}}>FABRİKA</div>
            Maliyet
            <div style={{fontWeight:'700',fontSize:12}}>{cost.toLocaleString()}₺</div>
            Nakliyat
            <div style={{fontWeight:'700',fontSize:12}}>{shipping}₺</div>
            Kàr
            <div style={{fontWeight:'700',fontSize:12}}>%{product.benefit?product.benefit:0}</div>
            Peşin
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(sellingPrice).toLocaleString()}₺</div>
          </div>
        </div>
        :null}

        {window.auth=='sales'||window.auth=='admin'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:12}}>
            <div style={{fontSize:14,fontWeight:'600',paddingBottom:3}}>TOPTAN</div>
            Peşin
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(sellingPrice).toLocaleString()}₺</div>
            K.Kartı Tek çekim
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(onePayment).toLocaleString()}₺</div>
            K.Kartı 3 Taksit
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(threePayment).toLocaleString()}₺</div>
            %25 Peşin kalan 6 Ay Vade
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(twentyFivePayment).toLocaleString()}₺</div>
            6 Ay Vade
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(sixPayment).toLocaleString()}₺</div>
          </div>
        </div>
        :null}

        {window.auth=='store'||window.auth=='admin'?
        <div style={{alignSelf:'start',flex:1}}>
          <div style={{fontSize:12}}>
            <div style={{fontSize:14,fontWeight:'600',paddingBottom:3}}>MAĞAZA</div>
            Satış
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(storePrice).toLocaleString()}₺</div>
            Minumum
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(minStorePrice).toLocaleString()}₺</div>
            Personel
            <div style={{fontWeight:'700',fontSize:12}}>{Math.round(employee).toLocaleString()}₺</div>
          </div>
        </div>
        :null}
      </div>
      {product.features?
      <div style={{padding:20}}>
        <div style={{fontSize:14,fontWeight:'600'}}>
          Ürün Ek Bilgisi
        </div>
        <div style={{fontSize:13}}>
          {product.features}
        </div>
      </div>
      :null}
      </>
      :null}
    </div>
  );
}
export default Product;
