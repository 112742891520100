import {useState,useEffect} from 'react';

const Alert=()=>{
  const [y,setY]=useState(0);

  useEffect(()=>{
    animation('up');
    setTimeout(()=>{animation('down')},3000);

  },[])

  function animation(to){
    let id=setInterval(frame,10);
    function frame(){
      setY(prev=>{
        if(to=='up'){
          if(prev<7){
            return prev+1;
          }
          else{
            clearInterval(id);
            return 7;
          }
        }
        else{
          if(prev>0){
            return prev-1;
          }
          else{
            clearInterval(id);
            window.setAlert(false);
            return 0;
          }
        }
      })
    }
  }

  return (
    <div style={{zIndex:2,position:'fixed',width:'100%',height:'7%',bottom:`${y-7}%`,background:window.color.six,borderRadius:10}}>
      {window.alert.message}
    </div>
  );
}
export default Alert;
