window.doc={};
window.functions={};
window.color={
  one:'rgb(0,0,0)',
  two:'rgb(50,50,50)',
  three:'rgb(100,100,100)',
  four:'rgb(150,150,150)',
  five:'rgb(200,200,200)',
  six:'rgb(255,255,255)',
}
window.dev=process.env.NODE_ENV=='development'?true:false;

function toEnglish(text){
  return text.replaceAll('Ğ','g').replaceAll('Ü','u')
  .replaceAll('Ş','s').replaceAll('I','i')
  .replaceAll('İ','i').replaceAll('Ö','o')
  .replaceAll('Ç','c').replaceAll('ğ','g')
  .replaceAll('ü','u').replaceAll('ş','s')
  .replaceAll('ı','i').replaceAll('ö','o')
  .replaceAll('ç','c');
}

window.functions.toEnglish=toEnglish;
