import {useState,useEffect,useRef,useMemo} from 'react';
import {collection,getDocs,query,where,orderBy,limit,startAfter,deleteField,doc,updateDoc} from 'firebase/firestore';
import {db} from '../firebase';
import Stock from './Stock';
import AddandEdit from '../Components/AddandEdit';

const Stocks=()=>{

  const [add,setAdd]=useState(false);
  const [data,setData]=useState([]);
  const [lastDoc,setLastDoc]=useState(null);
  const more=useRef(true);

  window.setAdd=setAdd;
  window.add=add;

  useEffect(()=>{
    if(!data[0]){
      getStocks();
    }
  },[])

  function getStocks(){
    const q=query(collection(db,'stocks'))

    if(q){
      window.setLoading(true);
      var d=[];
      getDocs(q).then((doc)=>{
        if(doc.size){
          setLastDoc(doc.docs[doc.docs.length-1]);
          doc.forEach((item)=>{
            d.push(item.data())
          });
          setData(d);
          more.current=true;
          window.doc.stocks=d;
          window.setLoading(false);
        }
        else{
          setData([]);
          window.setAlert({message:'Ürün bulunamadı.'})
          window.setLoading(false);
        }
      })
    }
    else{
      window.setAlert({message:'Firma Gir.'})
    }
  }

  const StockMemo=useMemo(()=>{
    if(data[0]){
      return data.map((i)=>{
        return <Stock stock={i}/>
      })
    }
  },[data])

  return(
    <div style={{width:'100%',height:'100%',justifyContent:'start'}}>
      <div style={{width:'100%',maxWidth:700,display:'block',justifyContent:'start'}}>
        <Search/>
        {StockMemo}
      </div>
      <div style={{position:'fixed',bottom:25,right:25}}>
        <button onClick={()=>{window.setNavigate('Products')}} style={{width:30,height:30,borderRadius:7}}>
          <img
          style={{width:20,height:20}}
          src={require('../Assets/StockIcon.png')}/>
        </button>
      </div>
      {add?
      <AddandEdit/>
      :null}
    </div>
  );
}
export default Stocks;








const Search=({})=>{
  const [showList,setShowList]=useState(false);

  return(
    <div style={{width:'100%',paddingBottom:10,paddingTop:10,background:window.color.five,borderRadius:20,marginBottom:5}}>
      <div
      onClick={()=>{setShowList(!showList)}}
      style={{padding:5,flexDirection:'row',width:'90%',justifyContent:'space-between'}}>
        <label style={{}}>
          <div style={{fontSize:20,letterSpacing:2,fontWeight:'bold'}}>
            ROSELLA
          </div>
          <div style={{fontSize:11,letterSpacing:2,marginTop:-5}}>
            Home Consept
          </div>
        </label>
        <img
        onClick={()=>{}}
        style={{width:30,height:30,transform:showList?'rotate(270deg)':'rotate(90deg)'}}
        src={require('../Assets/ArrowIcon.png')}/>
      </div>
    </div>
  );
}
