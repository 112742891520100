const ImageView=({imageView,setImageView})=>{
  return(
    <div
    onClick={()=>{setImageView(false)}}
    style={{zIndex:2,position:'fixed',width:'100%',height:'100%',left:0,top:0}}>
      <div style={{position:'absolute',width:'100%',height:'100%',background:'black',opacity:0.75}}/>
      <img
      style={{position:'absolute',width:'100%',objectFit:'contain',borderRadius:5}}
      src={imageView}/>
    </div>
  );
}

export default ImageView;
